import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/Seo/seo"
import Hero from "../../components/PageParts/WorkshopsSpeaking/Hero"
import Content from "../../components/PageParts/WorkshopsSpeaking/Content"
import CommunityNav from "../../components/PageParts/GivingBack/CommunityNav"

const WorkshopsSpeaking = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Workshops and Speaking - Switchback Creative."
        description="We believe that sharing what knowledge we can matters."
        location={props.location.pathname}
        pageImg={props.data.metaImage.publicURL}
      />
      <Hero />
      <Content />
      <CommunityNav location={props.location.pathname} />
    </Layout>
  )
}

export const workshopQuery = graphql`
  {
    metaImage: file(
      relativePath: { eq: "switchback-communitypages-meta.jpg" }
    ) {
      publicURL
    }
  }
`

export default WorkshopsSpeaking
