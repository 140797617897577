import React from "react"
import styled from "styled-components"

import {
  bodyCopy,
  headlineOne,
  headlineTwo,
  MedWrapper,
  fontSizer,
  buttonOne,
} from "../../../Utilities"
import CommunityInfo from "../About/CommunityData/CommunityCardData"
import ScriptTitle from "../../Shared/ScriptTitle"

const WrapperCommunity = styled.div`
  ${MedWrapper};
`

const CommunityCardStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    margin: 5rem auto;
  }

  @media (min-width: 1025px) {
    margin: 5rem auto 0;
  }

  .card-image-section {
    display: none;
    width: calc(100%);

    @media (min-width: 768px) {
      display: block;
      width: calc(30% - 2rem);
      margin-right: 2rem;
    }

    .icon {
      max-width: 10rem;
      margin: 3rem auto;
    }

    .image {
      margin: 2rem 1rem;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .card-info-section {
    width: calc(100%);
    padding-top: 1rem;

    @media (min-width: 768px) {
      width: calc(60% - 4rem);
      margin-left: 4rem;
      padding: 4rem;
    }

    h1 {
      ${headlineOne};
      ${fontSizer(2.2, 3.8, 76.8, 110, 2.6)}
      margin-top: 0;
      text-transform: uppercase;
    }

    p {
      ${headlineTwo};
      color: #707070;
      font-weight: 300;
    }

    li {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 110, 1.8)};
      margin-bottom: 1rem;
      font-weight: 300;
    }
  }

  .podcastRab {
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 5rem;

    p {
      ${bodyCopy};
    }

    a {
      ${buttonOne};
    }
  }

  .workshopList {
    width: 100%;

    h3 {
      ${headlineTwo(`#505050`)};
    }

    p {
      ${bodyCopy};
      margin-top: 5rem;

      a {
        ${bodyCopy};
        color: #707070;
      }
    }
  }
`

const Content = () => {
  return (
    <WrapperCommunity>
      <CommunityCardStyled className={`community-card`}>
        <div className="card-image-section">
          {/* <div className="icon">{CommunityInfo[1].svg}</div> */}
          <div className="image">{CommunityInfo[1].img}</div>
        </div>
        <div className="card-info-section">
          <div>
            <h1>{CommunityInfo[1].title}</h1>
            <p>
              We have been sharing our knowledge and the lessons we have learned
              over time in a way that gives more than just clients access to
              that information.
            </p>
          </div>

          <div className="podcastRab">
            <ScriptTitle title="<h2 class='title-script'>Raising a Business Podcast</h2>" />
            <div>
              <p>
                We created a podcast to discuss what it is like to Raise a
                Business, as business owners trying to build something amazing,
                as a husband and wife team, as people strengthening
                relationships and growing as human beings. Building a business
                is tough. But we can navigate it together. Through this platform
                we can share weekly how to grow your business, level up, market,
                pivot and fail forward.
              </p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://raisingabusiness.com/"
              >
                Our Podcast
              </a>
            </div>
          </div>

          <div className="workshopList">
            <h3>Workshops and Speaking Opportunities</h3>
            <div>
              <p>
                We conduct workshops and speaking events for your business,
                team, marketing department or lunch and learn event. It is a
                great way to elevate your teams understanding of what branding
                and marketing is and how it can move the hearts and minds of
                your customers to act - increasing sales and revenue for your
                business. We approach everything from a storytelling aspect and
                dig into how you can make your product or service part of your
                customers road map.{" "}
              </p>
              <p>
                <strong>
                  A few of the workshop topics we would love to speak with your
                  company on are:
                </strong>
              </p>
              <ul>
                <li>
                  Marketing | All about making people act. Turning customers
                  into raving fans.
                </li>
                <li>
                  Branding | All about making people feel something. Create
                  story based branding that speaks to the heart.
                </li>
                <li>
                  5 Keys to building a tribe of loyal buying customers. Don’t
                  waste your marketing dollars - do this instead.
                </li>
                <li>
                  Marketing Compass | Building a road map and brand story for
                  your business
                </li>
                <li>5 Ways to drive your business by telling stories</li>
              </ul>
              <p>
                If you have an event or group you want to book us for reach out
                to us with the details at:{" "}
                <a href="mailto:hello@switchbackcreative.ca">
                  hello@switchbackcreative.ca
                </a>
              </p>
            </div>
            <h3>Workshops and Speaking Engagement Experience</h3>
            <ul>
              {CommunityInfo[1].points.map((point, index) => {
                return <li key={index}>{point}</li>
              })}
            </ul>
            <p>
              If you have an event or group you want us to speak to reach out to{" "}
              <a href="mailto:hello@switchbackcreative.ca">
                hello@switchbackcreative.ca
              </a>
            </p>
          </div>
        </div>
      </CommunityCardStyled>
    </WrapperCommunity>
  )
}

export default Content
