import React from "react"
import CommunityWorkshopsHero from "../../../Elements/Images/CommunityWorkshopsHero"

const Hero = () => {
  return (
    <div>
      <CommunityWorkshopsHero />
    </div>
  )
}

export default Hero
