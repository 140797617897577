import React from "react"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const CommunityWorkshopsHeroStyled = styled(BgImg)`
  width: 100%;
  height: 40rem;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    height: 50rem;
  }

  @media (min-width: 1025px) {
    height: 65rem;
  }
`

const getData = graphql`
  {
    communityWorkshopsHero: file(
      relativePath: { eq: "community-workshops-hero.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const CommunityWorkshopsHero = props => {
  const data = useStaticQuery(getData)
  const imageData = data.communityWorkshopsHero.childImageSharp.fluid
  return <CommunityWorkshopsHeroStyled Tag="div" fluid={imageData} />
}

export default CommunityWorkshopsHero
